.sidebar {
   text-align: right;
  }

  .userwelcome-con {
    flex: 1;
    padding: 20px;
  }

  .sidebar {
    width: 300px;
    background-color: #f8f9fa;
    padding: 20px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul li {
    margin-bottom: 10px;
  }

  ul li a {
    text-decoration: none;
    color: #1E3A8A;
  }

  .logout-con {
    margin-top: auto;
  }
