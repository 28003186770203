.horizontal-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    text-align: right;
    width: 95%;
    direction: rtl;
}
.toExpand {
    display: flex;
    justify-content: flex-start;
    position: relative;
  top: -10px;
  left: 10px;
  }

  .horizontal-card-image {
    flex: 0 0 auto;
    margin-left: 20px;
  }

  .horizontal-card-image img {
    width: 200px;
    height: 200px;
  }

  .horizontal-card-body {
    flex: 1 1 auto;
  }

  .expanded {
    white-space: normal;
    overflow: visible;
    height: auto;
  }
  .toExpand button:hover{
    color: #FFA559;
  }
  .card-subtitle{
color: black;
font-weight: bold;
  }
  @media (max-width: 768px) {

    .horizontal-card{
      display: flex;
      flex-direction: column;
      width: 90%;
    }

  }


