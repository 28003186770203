.eventsData-container {
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .eventData-table {
    width: 75%;
  }


