.home-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    direction: rtl;
  }

  .top-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 380px;
    border: 1px solid #ccc;

    border-radius: 4px;
    margin-bottom: 20px;
  }
  .top-section{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width : 90%;
    max-height:400px;
  }
  .text-img-top{
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  .text-img-top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; /* normally it's the default value */

  }
  .top-section-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 164, 46, 0.4); /* Using an rgba value with transparency */
    color: white;
    padding: 10px;
  }

  .top-section-text h5 {
    margin: 0;
  }
  .top-section-button{

    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .events-projects-section {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    width: 90%;
  }

  .youtube-section {
    flex-basis: 60%;
  }
  .youtube-edit-container{
    width: 100%;
    height: 100%;
  }
  .event-projects-youtube-edit-button{
    display: flex;
    justify-content: flex-end;
  }
  .events-section,
  .projects-section {
    flex-basis: 30%;
    margin: 0 10px;
  }

  .card {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    text-align: center; /* Add text-align: center */
  }

  .card-img-top {
    width: 100%;
    height: 200px; /* Set a fixed height for the images */
    object-fit: cover; /* Ensure the images maintain aspect ratio and cover the container */
    border-radius: 4px;
    margin-bottom: 10px;
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .card-text#middle{
    font-size: 14px;

    color: #1E3A8A;
  }
  .card#middle{
    text-decoration: none!important;
  }
  .card-text#middle:hover{
    color:#FFA559;

  }


  .button-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin-top: 5px;
    margin-bottom: 20px;
    width: 95%;
  }


  .button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .button:hover {
    background-color: #e6e6e6;
  }
  .magazine-section{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .magazine-section button#magazine {
    width: 500px;
    background-color: #FFA559!important;
    border-color:#FFA559 !important;
  }
  .magazine-section button#magazine:hover{
    width: 550px;
    background-color: #fdb375!important;
    border-color: #FFA559!important;
    color: white!important;
  }
  @media (max-width: 768px) {

    .events-projects-section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
    .events-section,
    .projects-section,.youtube-section {
      margin-bottom: 20px;
      width: 90%;
      height: auto;
    }
    .magazine-section button#magazine {
      width: 90%;
      background-color: #FFA559;
      border-color:#FFA559 ;
    }
    .magazine-section button#magazine:hover{
      width: 100%;
      background-color: #fdb375;
      border-color: #FFA559;
    }
    .top-section{
      max-height:225px;
    }
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 20px;
      width: 95%;
    }
    .magazine-section{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
  }