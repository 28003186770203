.projects {
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
    flex-direction: column;
    text-align: center;
    background-color: white;
  }

  .carousel-container{
   width: 70%;
   border-radius: 20px;
   background-color: whitesmoke;
    margin-bottom: 20px;
  }
  .carousel-container img{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    background-color: white;
   }


  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the background color and opacity as needed */
    color: white;
    padding: 10px;
  }

  .carousel-caption h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .carousel-caption p {
    font-size: 16px;
  }
.carousel-indicators {
  bottom: 0%;
}

.carousel-indicators button
{
      background-color: #5f5f5f !important;
       /*more custom style*/
}

.carousel-indicators .active {
  background-color: #090909;
}
.project-caption{
  margin-bottom: 50px;
  margin-top: 20px;
}
input, textarea {
  text-align: right;
  margin-bottom: 5px;
}
.progress_data{
  display: flex;
  justify-content: flex-start;
  position: relative;
}
#createnewprojectbutton{
  width: 100%;
}

.newProject-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.newproject-buttons-container{
  display: flex;
  flex-direction: row;
}
@media (max-width: 768px) {
  .carousel-container{
    width: 95%;
    border-radius:  25px;

   }
   .carousel-container img{
    border-top-left-radius: 25px;
    border-top-right-radius:  25px;
    background-color: white;
   }
}