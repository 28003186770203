.contact-container{
display: flex;
flex-direction: row-reverse;
justify-content: flex-end;
direction: rtl;
}

.col{
    text-align: right;
}

.custom-social-orange {
    background-color: #FFA559;
}

.custom-social-darkblue {
    background-color: #2c2f66;
}
.contacts-info{
    display: flex;
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
    flex-direction: column;
color: #2c2f66;
font-size: 22px; /* Adjust the font size to your preference */
}
.info-text{
    background-color: white;
    padding:  5px;
    border-radius: 5px;
    margin-top: 30px;
}
@media (max-width: 768px) {
    .contact-container{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        }
    .contacts-info{
        text-align: center;
    }


  }