.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ccc;
  height: 100px;
}

.logo-container {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: 30px;
}

.logo-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li a {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
}
li.active a{
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  text-decoration: none;
  color: #ffa559;
}

a:hover {
  color: #ffa559;
}

.mobile-menu-button {
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.5rem;
    color: black;
    position: absolute;
    top: 6rem;
    right: 1rem;
    z-index: 2;
  }

  .mobile-menu-button svg {
    font-size: 1.5rem;
  }

  .nav-links {
    display: none;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    background-color: #f9f9f9;
    padding: 1rem;
    position: absolute;
    top: 150px;
    z-index: 1;
    left: 0;
    transition: all 0.3s ease-in-out;
  }

  .nav-links.mobile-open {
    display: flex;

    z-index: 2;
  }

  .nav-links li {
    margin-bottom: 0.5rem;
  }

  .nav-links li a {
    padding: 0.5rem 0;
    color: black;
  }
  .logo-container {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: 20px;
  }
}
