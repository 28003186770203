.addevent-container{
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFA559;
}
.addevent-form{
    width: 70%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.required-indicator{
    color: red;
}
.blank-row {
    height: 10px;
  }
  #submit-addevent{
    display: flex;
    justify-content: center;
  }
  @media (max-width: 768px) {
    .addevent-form{
        width: 80%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
    }


  }