.App {
  margin-left: 5%;
  margin-right: 5%;
  background-color: white;
  position: relative;
  min-height: 100vh;
}
.btn-primary{
  color: white!important;
  background-color:#1E3A8A !important;
  border-color: #1E3A8A !important;
}
.btn-secondary{
  color: white!important;
}
.btn-success{
  color: white !important;
}
.btn-danger{
  color: white !important;
}
.btn-primary:hover{
  color: #FFA559!important;
}
.btn-secondary:hover{
  color: #FFA559!important;
}

@media (max-width: 768px) {
  .App {
   margin:0;
    background-color: white;
    position: relative;
    min-height: 100vh;
  }


}


