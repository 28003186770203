.about {
    display: flex;
    direction: rtl;
    flex-direction: row-reverse;
  }

  .about__text {
    margin-left: auto;
    text-align: right;
    margin-top: 20px;
    margin-right: 20px;
  }

  .about__image {
    align-items: center;
    flex: 0 0 auto;
    margin-right: 20px; /* Adjust the margin as desired */
    width: 500px;
    height: 300px;
  }

  .about__image img {

    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
    height: 75%;
  }
  .about__text textarea {
    height: 200px;
    width: 500px;
    text-align: right;
  }
  @media (max-width: 768px) {

    .about{
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
      width: 100%;
    }
    .about__image  {
      display: flex;
      justify-content: center;
      width: 90%;
    }
    .about__image img {
      width: 100%;
    }

  }
