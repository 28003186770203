.p-4  {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}

.Auth-form {
width: 100%;
max-width: 420px;
box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
padding-top: 30px;
padding-bottom: 20px;
border-radius: 8px;
background-color: #FFA559;
}

.mb-3 {

padding-left: 12%;
padding-right: 12%;
}

.Auth-form-title {
text-align: center;
margin-bottom: 1em;
font-size: 24px;
color: rgb(34, 34, 34);

}

.d-grid {
  display: flex;
  justify-content: center;
}

.d-grid .btn {
  width: 100%;
}
#login-button{
  color: white;
}
