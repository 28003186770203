#donation-b {
    background-color: #1E3A8A;
    border-color: #1E3A8A;

}
#donation-b a{
    color: white!important
}
#donation-b:hover {
    background-color: #FFFAF4;
    border-color: #1E3A8A;
}
#donation-b:hover  a{
   color: black!important
}