.footer{
    background-color: #DDE6ED;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    color: #2c2f66;
    text-align: right;
    padding-top: 10px;
}
.footer a{
    color: #2c2f66;
}
.footer a:hover{
    color: #FFA559;
}
.subscribe-container-footer{
    color: #2c2f66;
}
.footer-right-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

}
.social-links-footer img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    filter: invert(18%) sepia(8%) saturate(7466%) hue-rotate(209deg) brightness(93%) contrast(92%);
    /* logo text color :#2c2f66 */
  }
  .logo-container-footer{
    width: 150px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: 30px;
    margin-bottom: 50px;
  }
  .logo-container-footer img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .user-container-footer a{
    text-decoration: none;
  }
  .contant-ul{
    margin-bottom: 20px;
  }
@media (max-width: 768px) {
    .footer{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .footer-right-container{
        margin-bottom: 30px;
    }
    .logo-container-footer{

        margin-bottom: 20px;
      }
      .contant-footer-container{
        margin-bottom: 20px;
      }
      .subscribe-container-footer{
        margin-bottom: 20px;
      }
      .pages-footer{
        margin-bottom: 20px;
      }
      .subscribe-container-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        color: #2c2f66;
    }
}